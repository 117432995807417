import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'offline',
    loadChildren: () => import('./pages/offline/offline.module').then( m => m.OfflinePageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },

  // Dashboard
  {
    path: 'dashboard', // Dashboard
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'D101', menuParentCode: '' }
  },

  // Reports
  {
    path: 'reports/sales',
    loadChildren: () => import('./pages/reports/reports-sales/reports-sales.module').then( m => m.ReportsSalesPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'R101A0120', menuParentCode: 'R101' }
  },
  {
    path: 'reports/customer',
    loadChildren: () => import('./pages/reports/reports-customer/reports-customer.module').then( m => m.ReportsCustomerPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'R101A0140', menuParentCode: 'R101' }
  },
  {
    path: 'reports/product',
    loadChildren: () => import('./pages/reports/reports-product/reports-product.module').then( m => m.ReportsProductPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'R101A0160', menuParentCode: 'R101' }
  },

  // Master
  {
    path: 'stores',
    loadChildren: () => import('./pages/masters/stores/stores.module').then( m => m.StoresPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'M101A0200', menuParentCode: 'M101' }
  },
  {
    path: 'products',
    loadChildren: () => import('./pages/masters/products/products.module').then( m => m.ProductsPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'M101A0220', menuParentCode: 'M101' }
  },
  {
    path: 'products/add',
    loadChildren: () => import('./pages/masters/products-add/products-add.module').then( m => m.ProductsAddPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'M101A0220', menuParentCode: 'M101' }
  },
  /*{
    path: 'products/upload',
    loadChildren: () => import('./pages/products-upload/products-upload.module').then( m => m.ProductsUploadPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'M101A0220', menuParentCode: 'M101' }
  },
  {
    path: 'products/publish',
    loadChildren: () => import('./pages/products-publish/products-publish.module').then( m => m.ProductsPublishPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'M101A0220', menuParentCode: 'M101' }
  },*/
  {
    path: 'categories',
    loadChildren: () => import('./pages/masters/categories/categories.module').then( m => m.CategoriesPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'M101A0240', menuParentCode: 'M101' }
  },
  {
    path: 'categories/add',
    loadChildren: () => import('./pages/masters/categories-add/categories-add.module').then( m => m.CategoriesAddPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'M101A0240', menuParentCode: 'M101' }
  },
  {
    path: 'banners',
    loadChildren: () => import('./pages/masters/banners/banners.module').then( m => m.BannersPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'M101A0380', menuParentCode: 'M101' }
  },
  {
    path: 'banners/add',
    loadChildren: () => import('./pages/masters/banners-add/banners-add.module').then( m => m.BannersAddPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'M101A0380', menuParentCode: 'M101' }
  },
  {
    path: 'routes',
    loadChildren: () => import('./pages/masters/routes/routes.module').then( m => m.RoutesPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'M101A0370', menuParentCode: 'M101' }
  },
  {
    path: 'brands',
    loadChildren: () => import('./pages/masters/brands/brands.module').then( m => m.BrandsPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: '101A0260', menuParentCode: 'M101' }
  },

  // Sales Transactions
  {
    path: 'store-visit/add',
    loadChildren: () => import('./pages/sales/store-visit/store-visit-add/store-visit-add.module').then( m => m.StoreVisitAddPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S101A0420', menuParentCode: 'S101' } // CHANGE MENUCODE AND MENUPARENTCODE
  },
  {
    path: 'store-visit/list',
    loadChildren: () => import('./pages/sales/store-visit/store-visit-list/store-visit-list.module').then( m => m.StoreVisitListPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S101A0420', menuParentCode: 'S101' } // CHANGE MENUCODE AND MENUPARENTCODE
  },
  {
    path: 'buyer/home',
    loadChildren: () => import('./pages/sales/buyer/buyer-home/buyer-home.module').then( m => m.BuyerHomePageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S101A0420', menuParentCode: 'S101' }
  },
  {
    path: 'buyer/products',
    loadChildren: () => import('./pages/sales/buyer/buyer-products/buyer-products.module').then( m => m.BuyerProductsPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S101A0420', menuParentCode: 'S101' }
  },
  {
    path: 'buyer/basket',
    loadChildren: () => import('./pages/sales/buyer/buyer-basket/buyer-basket.module').then( m => m.BuyerBasketPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S101A0420', menuParentCode: 'S101' }
  },
  {
    path: 'buyer/orders',
    loadChildren: () => import('./pages/sales/buyer/buyer-orders/buyer-orders.module').then( m => m.BuyerOrdersPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S101A0420', menuParentCode: 'S101' }
  },
  {
    path: 'delivery-note/add',
    loadChildren: () => import('./pages/sales/delivery-note/delivery-note-add/delivery-note-add.module').then( m => m.DeliveryNoteAddPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S101A0440', menuParentCode: 'S101' }
  },
  {
    path: 'delivery-note/list',
    loadChildren: () => import('./pages/sales/delivery-note/delivery-note-list/delivery-note-list.module').then( m => m.DeliveryNoteListPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S101A0440', menuParentCode: 'S101' }
  },
  {
    path: 'invoice/add',
    loadChildren: () => import('./pages/sales/invoice/invoice-add/invoice-add.module').then( m => m.InvoiceAddPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S101A0460', menuParentCode: 'S101' }
  },
  {
    path: 'invoice/list',
    loadChildren: () => import('./pages/sales/invoice/invoice-list/invoice-list.module').then( m => m.InvoiceListPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S101A0460', menuParentCode: 'S101' }
  },
  {
    path: 'performa-invoice/add',
    loadChildren: () => import('./pages/sales/performa-invoice/performa-invoice-add/performa-invoice-add.module').then( m => m.PerformaInvoiceAddPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S101A0480', menuParentCode: 'S101' }
  },
  {
    path: 'performa-invoice/list',
    loadChildren: () => import('./pages/sales/performa-invoice/performa-invoice-list/performa-invoice-list.module').then( m => m.PerformaInvoiceListPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S101A0480', menuParentCode: 'S101' }
  },
  {
    path: 'receipts/add', // Add receipts
    loadChildren: () => import('./pages/sales/receipts/receipts-add/receipts-add.module').then( m => m.ReceiptsAddPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S101A0500', menuParentCode: 'S101' }
  },
  {
    path: 'receipts/list', // List receipts
    loadChildren: () => import('./pages/sales/receipts/receipts-list/receipts-list.module').then( m => m.ReceiptsListPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S101A0500', menuParentCode: 'S101' }
  },
  {
    path: 'returns/add/sales', // Add sales returns
    loadChildren: () => import('./pages/sales/returns/returns-add/returns-add.module').then( m => m.ReturnsAddPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S101A0520', menuParentCode: 'S101' }
  },
  {
    path: 'returns/list/sales', // List sales returns
    loadChildren: () => import('./pages/sales/returns/returns-list/returns-list.module').then( m => m.ReturnsListPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S101A0520', menuParentCode: 'S101' }
  },
  {
    path: 'returns/add/damage', // Add damage returns
    loadChildren: () => import('./pages/sales/returns/returns-add/returns-add.module').then( m => m.ReturnsAddPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S101A0540', menuParentCode: 'S101' }
  },
  {
    path: 'returns/list/damage', // List damage returns
    loadChildren: () => import('./pages/sales/returns/returns-list/returns-list.module').then( m => m.ReturnsListPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S101A0540', menuParentCode: 'S101' }
  },
  {
    path: 'van-load-request/add', // Add van load request
    loadChildren: () => import('./pages/sales/van-load-request/van-load-request-add/van-load-request-add.module').then( m => m.VanLoadRequestAddPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S101A0555', menuParentCode: 'S101' }
  },
  {
    path: 'van-load-request/list', // List van load request
    loadChildren: () => import('./pages/sales/van-load-request/van-load-request-list/van-load-request-list.module').then( m => m.VanLoadRequestListPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S101A0555', menuParentCode: 'S101' }
  },
  {
    path: 'van-load/add', // Add van load
    loadChildren: () => import('./pages/sales/van-load/van-load-add/van-load-add.module').then( m => m.VanLoadAddPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S101A0560', menuParentCode: 'S101' }
  },
  {
    path: 'van-load/list', // List van load
    loadChildren: () => import('./pages/sales/van-load/van-load-list/van-load-list.module').then( m => m.VanLoadListPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S101A0560', menuParentCode: 'S101' }
  },
  {
    path: 'van-load-closing/add',
    loadChildren: () => import('./pages/sales/van-load-closing/van-load-closing-add/van-load-closing-add.module').then( m => m.VanLoadClosingAddPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S101A0561', menuParentCode: 'S101' }
  },
  {
    path: 'van-load-closing/list',
    loadChildren: () => import('./pages/sales/van-load-closing/van-load-closing-list/van-load-closing-list.module').then( m => m.VanLoadClosingListPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S101A0561', menuParentCode: 'S101' }
  },
  {
    path: 'van-sale/add',
    loadChildren: () => import('./pages/sales/van-sale/van-sale-add/van-sale-add.module').then( m => m.VanSaleAddPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S101A0410', menuParentCode: 'S101' }
  },
  {
    path: 'van-sale/list',
    loadChildren: () => import('./pages/sales/van-sale/van-sale-list/van-sale-list.module').then( m => m.VanSaleListPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S101A0410', menuParentCode: 'S101' }
  },

  // Sales Managements
  {
    path: 'store-visit',
    loadChildren: () => import('./pages/sales/store-visit/store-visit/store-visit.module').then( m => m.StoreVisitPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S110A0620', menuParentCode: 'S110' } // CHANGE MENUCODE AND MENUPARENTCODE
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/sales/orders/orders.module').then( m => m.OrdersPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S110A0620', menuParentCode: 'S110' }
  },
  {
    path: 'delivery-note',
    loadChildren: () => import('./pages/sales/delivery-note/delivery-note/delivery-note.module').then( m => m.DeliveryNotePageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S110A0640', menuParentCode: 'S110' }
  },
  {
    path: 'invoice',
    loadChildren: () => import('./pages/sales/invoice/invoice/invoice.module').then( m => m.InvoicePageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S110A0660', menuParentCode: 'S110' }
  },
  {
    path: 'performa-invoice',
    loadChildren: () => import('./pages/sales/performa-invoice/performa-invoice/performa-invoice.module').then( m => m.PerformaInvoicePageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S110A0680', menuParentCode: 'S110' }
  },
  {
    path: 'receipts', // Manage receipts
    loadChildren: () => import('./pages/sales/receipts/receipts/receipts.module').then( m => m.ReceiptsPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S110A0700', menuParentCode: 'S110' }
  },
  {
    path: 'returns/sales', // Manage sales returns
    loadChildren: () => import('./pages/sales/returns/returns/returns.module').then( m => m.ReturnsPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S110A0720', menuParentCode: 'S110' }
  },
  {
    path: 'returns/damage', // Manage damage returns
    loadChildren: () => import('./pages/sales/returns/returns/returns.module').then( m => m.ReturnsPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S110A0740', menuParentCode: 'S110' }
  },
  {
    path: 'van-load-request', // Manage van load request
    loadChildren: () => import('./pages/sales/van-load-request/van-load-request/van-load-request.module').then( m => m.VanLoadRequestPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S110A0755', menuParentCode: 'S110' }
  },
  {
    path: 'van-load', // Manage van load
    loadChildren: () => import('./pages/sales/van-load/van-load/van-load.module').then( m => m.VanLoadPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S110A0760', menuParentCode: 'S110' }
  },
  {
    path: 'van-load-closing',
    loadChildren: () => import('./pages/sales/van-load-closing/van-load-closing/van-load-closing.module').then( m => m.VanLoadClosingPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'S110A0760', menuParentCode: 'S110' }
  },

  // Accounts Transactoin
  {
    path: 'payment-voucher-staff/add',
    loadChildren: () => import('./pages/accounts/payment-voucher-staff-add/payment-voucher-staff-add.module').then( m => m.PaymentVoucherStaffAddPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'A101A1350', menuParentCode: 'A101' }
  },
  {
    path: 'payment-voucher-staff/list',
    loadChildren: () => import('./pages/accounts/payment-voucher-staff-list/payment-voucher-staff-list.module').then( m => m.PaymentVoucherStaffListPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'A101A1350', menuParentCode: 'A101' }
  },

  // Accounts Management
  {
    path: 'payment-voucher-staff',
    loadChildren: () => import('./pages/accounts/payment-voucher-staff/payment-voucher-staff.module').then( m => m.PaymentVoucherStaffPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'A110A1550', menuParentCode: 'A110' }
  },

  // Inventory Transactoin
  {
    path: 'daily-production-stock/add',
    loadChildren: () => import('./pages/inventory/daily-production-stock-add/daily-production-stock-add.module').then( m => m.DailyProductionStockAddPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'I101A2220', menuParentCode: 'I101' }
  },
  {
    path: 'daily-production-stock/list',
    loadChildren: () => import('./pages/inventory/daily-production-stock-list/daily-production-stock-list.module').then( m => m.DailyProductionStockListPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'I101A2220', menuParentCode: 'I101' }
  },

  // Inventory Management
  {
    path: 'daily-production-stock',
    loadChildren: () => import('./pages/inventory/daily-production-stock/daily-production-stock.module').then( m => m.DailyProductionStockPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'I110A2620', menuParentCode: 'I110' }
  },

  // Purchase Transactoin
  {
    path: 'purchase-invoice/add',
    loadChildren: () => import('./pages/purchase/purchase-invoice-add/purchase-invoice-add.module').then( m => m.PurchaseInvoiceAddPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'I110A2620', menuParentCode: 'I110' } // UPDATE MENUCODE & MENUPARENTCODE
  },
  {
    path: 'purchase-invoice/list',
    loadChildren: () => import('./pages/purchase/purchase-invoice-list/purchase-invoice-list.module').then( m => m.PurchaseInvoiceListPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'I110A2620', menuParentCode: 'I110' } // UPDATE MENUCODE & MENUPARENTCODE
  },

  // Purchase Management
  {
    path: 'purchase-invoice',
    loadChildren: () => import('./pages/purchase/purchase-invoice/purchase-invoice.module').then( m => m.PurchaseInvoicePageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'I110A2620', menuParentCode: 'I110' } // UPDATE MENUCODE & MENUPARENTCODE
  },


  // Utilities
  {
    path: 'users',
    loadChildren: () => import('./pages/utilities/users/users.module').then( m => m.UsersPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'U101A7100', menuParentCode: 'U101' }
  },
  {
    path: 'license',
    loadChildren: () => import('./pages/utilities/license/license.module').then( m => m.LicensePageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'U101A7155', menuParentCode: 'U101' }
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/utilities/settings/settings.module').then( m => m.SettingsPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'U101A7200', menuParentCode: 'U101' }
  },
  {
    path: 'settings/order',
    loadChildren: () => import('./pages/utilities/settings-order/settings-order.module').then( m => m.SettingsOrderPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'U101A7200', menuParentCode: 'U101' }
  },
  {
    path: 'settings/delivery',
    loadChildren: () => import('./pages/utilities/settings-delivery/settings-delivery.module').then( m => m.SettingsDeliveryPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'U101A7200', menuParentCode: 'U101' }
  },
  {
    path: 'settings/brand',
    loadChildren: () => import('./pages/utilities/settings-brand/settings-brand.module').then( m => m.SettingsBrandPageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'U101A7200', menuParentCode: 'U101' }
  },

  // Profile
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule),
    canActivate: [AuthGuardService],
    data: { menuCode: 'P101', menuParentCode: '' }
  },
  
  // Popup pages
  {
    path: 'pick-seller-buyer',
    loadChildren: () => import('./pages/sales/buyer/pick-seller-buyer/pick-seller-buyer.module').then( m => m.PickSellerBuyerPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'product-details',
    loadChildren: () => import('./pages/sales/buyer/product-details/product-details.module').then( m => m.ProductDetailsPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'delivery-slots',
    loadChildren: () => import('./pages/sales/buyer/delivery-slots/delivery-slots.module').then( m => m.DeliverySlotsPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'unit-picker',
    loadChildren: () => import('./pages/unit-picker/unit-picker.module').then( m => m.UnitPickerPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'image-picker',
    loadChildren: () => import('./pages/image-picker/image-picker.module').then( m => m.ImagePickerPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'product-unit-tax-picker',
    loadChildren: () => import('./pages/product-unit-tax-picker/product-unit-tax-picker.module').then( m => m.ProductUnitTaxPickerPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'transaction-pick-product',
    loadChildren: () => import('./pages/transaction-pick-product/transaction-pick-product.module').then( m => m.TransactionPickProductPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/change-password/change-password.module').then( m => m.ChangePasswordPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'pincode-picker',
    loadChildren: () => import('./pages/pincode-picker/pincode-picker.module').then( m => m.PincodePickerPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'brand-picker',
    loadChildren: () => import('./pages/brand-picker/brand-picker.module').then( m => m.BrandPickerPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'stores-map-view',
    loadChildren: () => import('./pages/stores-map-view/stores-map-view.module').then( m => m.StoresMapViewPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'lookup',
    loadChildren: () => import('./pages/lookup/lookup.module').then( m => m.LookupPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'store-edit',
    loadChildren: () => import('./pages/masters/store-edit/store-edit.module').then( m => m.StoreEditPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'favorites-picker',
    loadChildren: () => import('./pages/favorites-picker/favorites-picker.module').then( m => m.FavoritesPickerPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'item-picker',
    loadChildren: () => import('./pages/item-picker/item-picker.module').then( m => m.ItemPickerPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'sales-order-picker',
    loadChildren: () => import('./pages/sales-order-picker/sales-order-picker.module').then( m => m.SalesOrderPickerPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'transaction-picker',
    loadChildren: () => import('./pages/transaction-picker/transaction-picker.module').then( m => m.TransactionPickerPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'collection-entry',
    loadChildren: () => import('./pages/collection-entry/collection-entry.module').then( m => m.CollectionEntryPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: '', 
    redirectTo: 'login',
    pathMatch: 'full'
  },  {
    path: 'barcode-scanner',
    loadChildren: () => import('./pages/barcode-scanner/barcode-scanner.module').then( m => m.BarcodeScannerPageModule)
  },

  

  
  
  /*{ 
    path: '**', 
    redirectTo: 'login',
    pathMatch: 'full'
  },*/
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
