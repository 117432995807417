// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  production: false,
  ngxLogger: {
    //apiUrl: '',
    //serverLogLevel: NgxLoggerLevel.TRACE,
    logLevel: NgxLoggerLevel.LOG,
    disableConsoleLogging: false
  },
  //apiUrl: 'http://localhost:8081/',
  //apiUrl: 'http://b4m-api-dev-env.ap-south-1.elasticbeanstalk.com/',
  apiUrl: 'https://dev-api.basket4me.com:8443/', // DEV
  b2bAppKey: 'b4m-pwa-b2b-dev-app-key', // DEV
  b2bAppEncryptionKey: 'pwa-app-encryption-key-dev', // DEV
  googleMapKey: 'AIzaSyAiz9Yvi2wGGEKsHSUfVJij6SMfXwJgV-A',
  googleMapLiveMarker: "./assets/images/live-marker.png",
  googleMapB4MMarkerBlue: "./assets/images/b4m-marker-blue.png",
  googleMapB4MMarkerRed: "./assets/images/b4m-marker-red.png",
  serverErrorMessage: 'Server error. Please try again later.',
  apiErrorMessage: 'API error. Please try again later.',
  readStorageDataErrorMessage: 'Error while loading data. Please try again.',
  logoImgPath: 'https://b4m-ui-assets.s3.ap-south-1.amazonaws.com/dev/logos/',
  businessCardImgPath: 'https://b4m-ui-assets.s3.ap-south-1.amazonaws.com/dev/business-cards/',
  sildeImgPath: 'https://b4m-ui-assets.s3.ap-south-1.amazonaws.com/dev/slides/',
  offerImgPath: 'https://b4m-ui-assets.s3.ap-south-1.amazonaws.com/dev/offers/',
  categryImgPath: 'https://b4m-ui-assets.s3.ap-south-1.amazonaws.com/dev/categories/',
  productImgPath: 'https://b4m-ui-assets.s3.ap-south-1.amazonaws.com/dev/products/',
  storeCategoryImgPath: 'https://b4m-ui-assets.s3.ap-south-1.amazonaws.com/dev/store-categories/',
  firebase: {
    projectId: 'basket4me-firebase',
    appId: '1:568862162020:web:ef7a46b05240e1c14d7371',
    storageBucket: 'basket4me-firebase.appspot.com',
    apiKey: 'AIzaSyBTOgPrQIEanY9of8aiF71k5fqso5Xkrkw',
    authDomain: 'basket4me-firebase.firebaseapp.com',
    messagingSenderId: '568862162020',
    measurementId: 'G-PLWDG333G3',
  },
  aws: {
    accessKeyId: 'AKIA6EJ7ZMV47YIZONVE', // AKIATDWMUL3YA7XDKGEE
    secretAccessKey: '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', // Kei0tBxF1gsw4fI4U75pqFcIIqNIcNFODH9kC2Pe
    region: 'ap-south-1',
    bucket: 'b4m-ui-assets',
    logoImgFolder: 'dev/logos/',
    businessCardImgFolder: 'dev/business-cards/',
    slideImgFolder: 'dev/slides/',
    offerImgFolder: 'dev/offers/',
    categryImgFolder: 'dev/categories/',
    productImgFolder: 'dev/products/',
  },
  storeModes: [
    { storeCategoryId: 'RT', storeCategory: "Retailer" },
    { storeCategoryId: 'MF', storeCategory: "Manufacturer" },
    { storeCategoryId: 'DT', storeCategory: "Distributor" },
    { storeCategoryId: 'RD', storeCategory: "Retailer & Distributor" }
  ],
  transactionCode: {
    salesOrder: 'SO',
    receipt: 'RT',
    salesReturn: 'SR',
    damageReturn: 'DR',
    vanLoadRequest: 'VLR',
    vanLoad: 'VL',
    vanLoadClosing: 'VLC',
    taxInvoice: 'INV',
    performaInvoice: 'PINV',
    deliveryNote: 'DN',
    vanSale: 'VS',
    paymentVoucherStaff: 'PVS',
    dailyProductionStock: 'DPS',
    purchaseOrder: 'PO',
    purchaseInvoice: 'PUINV',
    purchaseReturn: 'PR',
    SupplierPayment: "PY",
    StoreVisit: "STV"
  },
  transactionStatusList: [
    { id: 2, status: 'Submitted' },
    { id: 3, status: 'Approved' },
    { id: 4, status: 'Rejected' },
    { id: 5, status: 'Deleted' }
  ],
  paymentTypes: [
    { id: 'cash', name: 'Cash' },
    { id: 'cheque', name: 'Cheque' },
    { id: 'upi', name: 'UPI' },
    { id: 'bank', name: 'Bank' }
  ],
  productMenu: {
    menuId: '3', 
    menuCode: 'M101A0220'
  },
  emptyLogoImageBase64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC',
  pdfInfo: {
    title: 'Basket4Me Business',
    author: 'ZenBiz Technologies Pvt Ltd.'
  }
  
};
