import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { Platform, ToastController, AlertController, LoadingController } from '@ionic/angular';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';

import * as _ from 'lodash';
import * as moment from 'moment';
import * as CryptoJS from 'crypto-js';
import { S3Client, GetObjectCommand, PutObjectCommand } from "@aws-sdk/client-s3";
import { Buffer } from 'buffer';
import { format } from 'date-fns';

import { environment } from '../../environments/environment';
import { MessageService } from 'src/app/services/message.service';

import { Device } from '@capacitor/device';
import { Network } from '@capacitor/network';
import { Loader } from '@googlemaps/js-api-loader';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  public globalLoading: any;

  // Global variables
  public FIREBASE_MESSAGING_TOKEN: any;
  public LOGIN_INFO: any = [];

  private s3ClientObj: S3Client;

  constructor(
    private _platform: Platform,
    private _router: Router,
    private _storage: Storage,
    private _toastController: ToastController,
    private _alertController: AlertController,
    private _loadingController: LoadingController,
    private _messageService: MessageService,
    private _httpClient: HttpClient,
  ) {
    // Create S3 client object
    this.s3ClientObj = new S3Client(
      {
        credentials: {
          accessKeyId: environment.aws.accessKeyId,
          secretAccessKey: environment.aws.secretAccessKey,
        },
        region: environment.aws.region,
      }
    );

    // Load google maps javaScript API script
    const loader = new Loader({
      apiKey: environment.googleMapKey,
      version: "weekly",
      libraries: ["places"]
    });

    loader.importLibrary('maps').then(({Map}) => {
      // Google maps library is loaded
    }).catch((error) => {
      //this.showToastMessage(JSON.stringify(error), 3000, 'error');
    });
  }

  // Check platform -----------------
  checkPlatform(platformName: any) {
    return this._platform.is(platformName);
  }

  // Check internet connection -----------------
  async checkInternetConnection() {
    let networkStatus = await Network.getStatus();
    return networkStatus.connected;
  }

  // Check device info -----------------
  async checkDeviceInfo() {
    let deviceInfo = await Device.getInfo();
    return deviceInfo;
  }

  // Get device id -----------------
  async getDeviceId() {
    let deviceId = await Device.getId();
    return deviceId;
  }

  // Show toast -----------------
  async showToastMessage(toastMessage: string, duration?: number, color?: string) {
    let toastDuration = 2000; // Default
    let toastColor = 'success'; // Default

    if (duration) {
      toastDuration = duration;
    }

    if (color) {
      toastColor = color;
    }

    const toast = await this._toastController.create({
      message: toastMessage,
      duration: toastDuration,
      position: 'top',
      color: toastColor,
      cssClass: 'toast-class',
      mode: 'ios'
    });
    toast.present();
  }

  // Set storage data -----------------
  setStorageData(key: any, value: any) {
    return new Promise(resolve => {
      try {
        this._storage.set(key, value).then(response => {
          if (response) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      } catch (error) {
        resolve(false);
      }
    });
  }

  // Get storage data -----------------
  getStorageData(key: any, decrypt?: boolean) {
    return new Promise(resolve => {
      try {
        this._storage.get(key).then(response => {
          if (decrypt) { // Decrypt data from storage
            resolve(this.decryptData(response));
          } else {
            resolve(response);
          }
        });
      } catch (error) {
        resolve(false);
      }
    });
  }

  // Remove storage data -----------------
  removeStorageData(key: any) {
    return new Promise(resolve => {
      try {
        this._storage.remove(key).then(response => {
          resolve(response);
        });
      } catch (error) {
        resolve(false);
      }
    });
  }

  // Show loading -----------------
  async showLoading() {
    this.globalLoading = await this._loadingController.create({
      spinner: 'circular'
    });
    await this.globalLoading.present();
  }

  // Hide loading -----------------
  async hideLoading() {
    this.globalLoading.dismiss();
  }

  // Encrypt Data -----------------
  encryptData(data: any) {
    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), environment.b2bAppEncryptionKey).toString();
    } catch (e) {
      return '';
    }
  }

  // Decrypt Data -----------------
  decryptData(data: any) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, environment.b2bAppEncryptionKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      } else {
        return data;
      }
    } catch (e) {
      return data;
    }
  }


  // Calculate offer percentage -----------------
  findOfferPercentage(actualPrice: any, offerPrice: any) {
    try {
      if (actualPrice && actualPrice > 0 && offerPrice && offerPrice > 0 && actualPrice > offerPrice) { // Add offertext
        let offerPercentage: any = ((actualPrice - offerPrice) / actualPrice) * 100;
        return Math.floor(offerPercentage) + '% off';
      } else { // Clear offertext
        return '';
      }
    } catch (error) {
      return '';
    }
  }

  // Check user access -----------------
  checkUserAccess(menuCode: any, accessList: any, pubishAccess: any) {
    return new Promise(async resolve => {
      try {
        if (menuCode.length === 0) { // Menu Id / Name is missing
          resolve(false);
        } else {
          let menuStatus = _.filter(accessList, item => item.menuCode === menuCode && item.viewAccess === 1);
          
          if (menuStatus.length === 0) { // Menu access not found
            resolve(false); // No menu access
          } else { // Menu access avilable
            if (pubishAccess === true) { // Check publish access
              if (menuStatus[0].publishAccess === 1) {
                resolve(true); // Publish access available
              } else {
                resolve(false); // No publish access
              }
            } else { // Check view access
              if (menuStatus[0].viewAccess === 1) {
                resolve(true); // View access available
              } else {
                resolve(false); // No view access
              }
            }
          }
        }
      } catch (error) {
        resolve(false);
      }
    });
  }

   // Check user menu access -----------------
   checkUserMenuAccess(menuId: any, menuCode: any) {
    let menuIdStatus: any = _.filter(this.LOGIN_INFO.menu, item => item.menuId == menuId);

    if (menuIdStatus.length > 0) {
      let menuCodeStatus: any = _.filter(menuIdStatus[0].subMenu, item => item.menuCode == menuCode);

      if (menuCodeStatus.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
   }

  // base64 to S3 -----------------
  s3Base64Upload(file: any, base64: any, bucket_name: string, folder: string, file_name_prefix?: string){
    return new Promise((resolve, reject) => {
      try {
        var imgData = new Buffer(base64.replace(/^data:image\/\w+;base64,/, ""),'base64')

        // Set file name
        let fileName: string = moment().unix() + '-' + Math.floor(Math.random() * 9999) + '.' + file.name.split('.')[1];  // Add timestamp
        if (file_name_prefix) {
          fileName = file_name_prefix + fileName;
        }

        const params = {
          Bucket: bucket_name,
          Key: folder + fileName,
          Body: imgData,
          ContentEncoding: 'base64', // Only for base64
          //Body: file,
          ContentType: file.type,
          //ACL: 'public-read',
        };

        this.s3ClientObj.send(new PutObjectCommand(params)).then(data => { // Return data
          const responseSuccess = {
            status: true,
            data: data,
            fileName: fileName
          };
          resolve(responseSuccess);
        }).catch(err => { // Return error
          const responseError = {
            status: false,
            error: err
          };
          resolve(responseError);
        });
      } catch (err) {
        return reject(err);
      }
    });
  }

  // Conver image file to base64 -----------------
  async imageFiletoBase64(file: File, maxSize: any): Promise<string | ArrayBuffer> {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      try {
        const file_size: any = file.size;
        const file_name: any = file.name.split('.');
      
        if ((file_size / 1024) > maxSize) {
          return reject('Image file size exceeds maximum limit');
        } else if (file_name.length > 2) {
          return reject("Image file name should not have '.' in it");
        } else if (file_name.length === 2 && (file_name[1].toLowerCase() === "jpg" || file_name[1].toLowerCase() === "jpeg" || file_name[1].toLowerCase() === "png")) {
          const reader = new FileReader();
          reader.onload = e => {
            //return resolve((e.target as FileReader).result);
            return resolve(reader?.result as string);
          };

          reader.onerror = e => {
            return reject(`FileReader failed on file ${file.name}.`);
          };

          if (!file) {
            return reject('No file to read');
          }

          reader.readAsDataURL(file);
        } else {
          return reject('Invalid image file selected');
        }
      } catch (err) {
        return reject(err);
      }
    });
  }

  // Save data locally -----------------
  saveDataLocally(key: string, fieldName: string, fieldData: string) {
    // Get existing data
    this.getStorageData(key, false). then(data =>{
      let tempData: any = data;
      if (!tempData) { // First entry
        tempData = { [fieldName]: fieldData }
      } else { // Update
        tempData[fieldName] = fieldData;
      }
      
      // Save updated data
      this.setStorageData(key, tempData).then(async responseDnTemp => {});
    });
  }

  // Get address from coordinates -----------------
  getAddressFromCoords(lattitude: any, longitude: any) {
    return new Promise((resolve, reject) => {
      try {
        let geocoder = new google.maps.Geocoder();
        let latlng = new google.maps.LatLng(lattitude, longitude);
        let request: any = {
          latLng: latlng
        };

        geocoder.geocode(request, (results, status) => {
          if (status == google.maps.GeocoderStatus.OK) {
            if (results![0] != null) {
              resolve(results![0].formatted_address);
            } else {
              resolve('');
            }
          }
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  // Get country from coordinates -----------------
  getCountryFromCoords(lattitude: any, longitude: any) {
    return new Promise((resolve, reject) => {
      try {
        let geocoder = new google.maps.Geocoder();
        let latlng = new google.maps.LatLng(lattitude, longitude);
        let request: any = {
          latLng: latlng
        };

        geocoder.geocode(request, (results, status) => {
          if (status == google.maps.GeocoderStatus.OK) {
            if (results![0] != null) {
              // Loop through address components to find country
              for (const component of results![0].address_components) {
                if (component.types.includes('country')) {
                  resolve(component.long_name);
                  return;
                }
              }
              
              reject('Country not found in address components')
            } else {
              reject('No results found');
            }
          }
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  // Dial number -----------------
  dialNumber(number: string) {
    window.open('tel:' + number);
  }

  // Find percentage value (what is p% of x) -----------------
  findPercentageValue(p: number, x: number) {
    //console.log('what is ' + p + '% of ' + x);
    if (p <=0 || x <=0){
      return 0;
    } else {
      return this.roundTo((+x * +p) / 100, 2);
    }
  }

  // Find value percentage (x is what percent of y) -----------------
  findValuePercentage(x: any, y: any) {
    //console.log(x + ' is what percent of ' + y);
    if (x <=0 || y <=0){
      return 0;
    } else {
      return this.roundTo((+x * 100) / +y, 2);
    }
  }

  // Round decimal -----------------
  roundTo(num: number, places: number) {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
  };

  // Get current date by timezone
  getCurrentDateTime(formatString: string): any {
    let offset: any = this.LOGIN_INFO.userStoreDetails.timeZone; // Offset of customer

    const now = new Date();
    const [sign, hours, minutes] = offset.match(/([+-]?)(\d{1,2}):(\d{2})/)!.slice(1);
    const totalOffsetMinutes = parseInt(hours) * 60 + parseInt(minutes);
    const offsetMilliseconds = (sign === '-' ? -1 : 1) * totalOffsetMinutes * 60000;
    const utcTime = now.getTime() + now.getTimezoneOffset() * 60000;
    const today = new Date(utcTime + offsetMilliseconds);
    
    return format(new Date(today), formatString); // Format the date
  }

  // Common API -----------------

  // Get images
  getImages(loginToken: string, userId: any, storeId: any, mode: any, name: any) {
    const URL = environment.apiUrl + 'api/business/getImages';

    let reqHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-access-token', loginToken)
      .set('x-device-id', this.LOGIN_INFO.deviceId);
      
    let reqParams = new HttpParams()
      .set('userId', userId) 
      .set('storeId', storeId)
      .set('mode', mode)
      .set('name', name);

    return this._httpClient.get<any>(URL, {headers: reqHeaders, params: reqParams});
  }

  // Change store logo, product, category & UPI image file name
  changeImage(loginToken: string, userId: any, storeId: any, body: any) {
    const URL = environment.apiUrl + 'api/business/changeImage';

    let reqHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-access-token', loginToken)
      .set('x-device-id', this.LOGIN_INFO.deviceId);

    let reqParams = new HttpParams()
      .set('userId', userId) 
      .set('storeId', storeId);

    let encryptedBody = this.encryptData(body);
    let reqBody = JSON.stringify({ data: encryptedBody});

    return this._httpClient.post<any>(URL, reqBody, {headers: reqHeaders, params: reqParams});
  }

  // Get pincodes
  getPincodes(loginToken: string, userId: any, storeId: any, page: any, search: any) {
    const URL = environment.apiUrl + 'api/business/pincodes';

    let reqHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-access-token', loginToken)
      .set('x-device-id', this.LOGIN_INFO.deviceId);
      
    let reqParams = new HttpParams()
      .set('userId', userId) 
      .set('storeId', storeId)
      .set('page', page)
      .set('search', search);

    return this._httpClient.get<any>(URL, {headers: reqHeaders, params: reqParams});
  }

  // Get brands list for a store
  getBrands(loginToken: string, userId: any, storeId: any, brandId: any) {
    const URL = environment.apiUrl + 'api/business/brands';

    let reqHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-access-token', loginToken)
      .set('x-device-id', this.LOGIN_INFO.deviceId);
      
    let reqParams = new HttpParams()
      .set('userId', userId) 
      .set('storeId', storeId)
      .set('brandId', brandId);

    return this._httpClient.get<any>(URL, {headers: reqHeaders, params: reqParams});
  }

  // Get stock locations list for a store
  getStockLocations(loginToken: string, userId: any, sStoreId: any, stockLocIdFilter: number) {
    const URL = environment.apiUrl + 'api/business/stockLocations';

    let reqHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-access-token', loginToken)
      .set('x-device-id', this.LOGIN_INFO.deviceId);
      
    let reqParams = new HttpParams()
      .set('userId', userId) 
      .set('sStoreId', sStoreId)
      .set('stockLocIdFilter', stockLocIdFilter);

    return this._httpClient.get<any>(URL, {headers: reqHeaders, params: reqParams});
  }

  // Get product unit list for a store
  getProductUnits(loginToken: string, userId: any, storeId: any, unitCode: any) {
    const URL = environment.apiUrl + 'api/business/productUnits';

    let reqHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-access-token', loginToken)
      .set('x-device-id', this.LOGIN_INFO.deviceId);
      
    let reqParams = new HttpParams()
      .set('userId', userId) 
      .set('storeId', storeId)
      .set('unitCode', unitCode);

    return this._httpClient.get<any>(URL, {headers: reqHeaders, params: reqParams});
  }

  // Get transaction print details
  getTransactionPrintDetails(loginToken: string, userId: any, sStoreId: any, bStoreId: any, tranTypeCd: any, tranRefNo: any) {
    const URL = environment.apiUrl + 'api/business/transactionPrintDetails';

    let reqHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-access-token', loginToken)
      .set('x-device-id', this.LOGIN_INFO.deviceId);
      
    let reqParams = new HttpParams()
      .set('userId', userId) 
      .set('sStoreId', sStoreId)
      .set('bStoreId', bStoreId)
      .set('tranTypeCd', tranTypeCd)
      .set('tranRefNo', tranRefNo);

    return this._httpClient.get<any>(URL, {headers: reqHeaders, params: reqParams});
  }

  // Get store outstanding balance
  getStoreOB(loginToken: string, userId: any, sStoreId: any, bStoreId: any, obDate: any, transactionCode: string) {
    const URL = environment.apiUrl + 'api/business/getStoreOB';

    let reqHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-access-token', loginToken)
      .set('x-device-id', this.LOGIN_INFO.deviceId);

    let reqParams = new HttpParams()
      .set('userId', userId)
      .set('sStoreId', sStoreId)
      .set('bStoreId', bStoreId)
      .set('obDate', obDate)
      .set('transactionCode', transactionCode);

    return this._httpClient.get<any>(URL, {headers: reqHeaders, params: reqParams});
  }

  // Merge sales orders
  mergeSalesOrders(loginToken: string, userId: any, sStoreId: any, bStoreId: any, mergeDate: any, body: any) {
    const URL = environment.apiUrl + 'api/business/mergeSalesOrders';

    let reqHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-access-token', loginToken)
      .set('x-device-id', this.LOGIN_INFO.deviceId);

      let reqParams = new HttpParams()
      .set('userId', userId)
      .set('sStoreId', sStoreId)
      .set('bStoreId', bStoreId)
      .set('mergeDate', mergeDate);

    let encryptedBody = this.encryptData(body);
    let reqBody = JSON.stringify({ data: encryptedBody});

    return this._httpClient.post<any>(URL, reqBody, {headers: reqHeaders, params: reqParams});
  }

  // Get pending transactions
  getPendingTransactions(loginToken: string, userId: any, sStoreId: any, bStoreId: any, requestDate: any, transactionCode: string, uniqueId: any) {
    const URL = environment.apiUrl + 'api/business/getPendingTransactions';

    let reqHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-access-token', loginToken)
      .set('x-device-id', this.LOGIN_INFO.deviceId);

    let reqParams = new HttpParams()
      .set('userId', userId)
      .set('sStoreId', sStoreId)
      .set('bStoreId', bStoreId)
      .set('requestDate', requestDate)
      .set('transactionCode', transactionCode)
      .set('uniqueId', uniqueId);

    return this._httpClient.get<any>(URL, {headers: reqHeaders, params: reqParams});
  }

  // Merge transaction products
  mergePendingTransactions(loginToken: string, userId: any, sStoreId: any, bStoreId: any, mergeDate: any, transactionCode: string, uniqueId: any, body: any) {
    const URL = environment.apiUrl + 'api/business/mergePendingTransactions';

    let reqHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-access-token', loginToken)
      .set('x-device-id', this.LOGIN_INFO.deviceId);

    let reqParams = new HttpParams()
      .set('userId', userId)
      .set('sStoreId', sStoreId)
      .set('bStoreId', bStoreId)
      .set('mergeDate', mergeDate)
      .set('transactionCode', transactionCode)
      .set('uniqueId', uniqueId);

    let encryptedBody = this.encryptData(body);
    let reqBody = JSON.stringify({ data: encryptedBody});

    return this._httpClient.post<any>(URL, reqBody, {headers: reqHeaders, params: reqParams});
  }

  // Get lookup list
  lookup(loginToken: string, userId: any, sStoreId: any, body: any) {
    const URL = environment.apiUrl + 'api/business/lookup';

    let reqHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-access-token', loginToken)
      .set('x-device-id', this.LOGIN_INFO.deviceId);

    let reqParams = new HttpParams()
      .set('userId', userId)
      .set('sStoreId', sStoreId);

    let encryptedBody = this.encryptData(body);
    let reqBody = JSON.stringify({ data: encryptedBody});
  
    return this._httpClient.post<any>(URL, reqBody, {headers: reqHeaders, params: reqParams});
  }
  
}
